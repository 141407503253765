import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { connectRefinementList } from "react-instantsearch-dom";
import { useTranslation } from "react-i18next";
import {
  colors,
  rem,
  scaleSpacing,
  zIndexes,
  mediaquery,
} from "../../../../styles/theme";
import useOutsideClick from "../../../hooks/useOutsideClick";

import IconSvg from "../../Atoms/Svg";
import Button from "../../Atoms/Button";
import { NotoLink, NotoSmall } from "../../Atoms/Typography";

const SelectWrapper = styled.div`
  position: relative;
  margin: ${scaleSpacing(4)} 0 0 0;
  z-index: ${zIndexes.top};
  display: flex;
  flex-direction: column;
  width: calc(100%);
  &:first-of-type {
    margin-left: 0;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      div {
        display: block;
      }
      > button span > span {
        transform: rotate3d(0, 0, 1, 90deg);
      }
    }
  }

  ${mediaquery.md(css`
    margin: 0 0 0 ${scaleSpacing(4)};
    width: calc(((100% - 16px) / 6) - (2 * ${scaleSpacing(2)}));
  `)};
`;

const SelectButton = styled(Button)`
  width: 100%;
  padding: 0 ${scaleSpacing(8)} 0 ${scaleSpacing(4)};
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  z-index: ${zIndexes.middle};
  & > span {
    justify-content: flex-start;
    width: 100%;
  }
`;

const SelectContainer = styled.div`
  display: ${({ active }) => (active ? "block" : "none")};
`;

const OptionWrapper = styled.div`
  position: relative;
  top: ${scaleSpacing(-12)};
  left: 0;
  border-top: ${({ isSearchable }) => scaleSpacing(isSearchable ? 22 : 13)}
    solid ${({ bgColor }) => bgColor};
  border-bottom: ${scaleSpacing(4)} solid ${({ bgColor }) => bgColor};
  padding: 0 0 ${scaleSpacing(2)};
  margin-top: 0;
  margin-left: 0;
  margin-bottom: ${scaleSpacing(-12)};
  border-radius: ${scaleSpacing(7)};
  background-color: ${({ bgColor }) => bgColor};
  max-height: 60vh;
  overflow: auto;
  width: 100%;

  ${mediaquery.md(css`
    max-height: 30vh;
    position: absolute;
    top: 0;
  `)};
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Option = styled(Button)`
  width: 100%;
  ${NotoLink}
  font-weight: 400;
  line-height: ${rem(20)};
  text-align: left;
  padding: ${scaleSpacing(3)} ${scaleSpacing(10)} 0 ${scaleSpacing(3)};
  text-decoration: none;
  color: ${({ color }) => color};
  font-weight: ${({ isRefined }) => (isRefined ? 500 : 300)};
  & > span {
    display: inline-block;
    width: 100%;
  }
`;

const Count = styled.i`
  color: ${colors.mediumgrey1};
  padding-left: ${scaleSpacing(1)};
`;

const SearchInputWrapper = styled.div`
  position: relative;
  top: ${scaleSpacing(-12)};
  left: 0;
  margin-bottom: ${scaleSpacing(-12)};
  border: none;
  border-radius: ${scaleSpacing(7)};
  padding: ${scaleSpacing(13)} 0 ${scaleSpacing(2)};
  z-index: ${zIndexes.bottom};
  box-sizing: content-box;
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;

  ${mediaquery.md(css`
    position: absolute;
    top: 0;
    margin: 0;
  `)};
`;

const NoResult = styled.div`
  width: 100%;
  ${NotoSmall}
  font-weight: 400;
  line-height: ${rem(20)};
  text-align: left;
  padding: ${scaleSpacing(3)} ${scaleSpacing(5)};
  text-decoration: none;
  color: ${({ color }) => color};
`;

const SearchInput = styled.input`
  margin: 0 ${scaleSpacing(3)};
  border: none;
  border-radius: ${scaleSpacing(2)};
  padding: ${scaleSpacing(2)};
  height: ${scaleSpacing(8)};
  width: calc(100% - 2 * ${scaleSpacing(3)});
  ${NotoSmall}
  &:focus {
    outline: ${({ bgColor }) => bgColor};
  }
`;

const Arrow = styled(IconSvg)`
  color: ${colors.mediumgrey1};
  position: absolute;
  right: ${scaleSpacing(5)};
  top: 0;
  bottom: 0;
  margin: auto;
`;

const Tick = styled(IconSvg)`
  position: absolute;
  top: ${scaleSpacing(2)};
  right: ${scaleSpacing(4)};
  margin: auto;
`;

const arrowDown = css`
  transform: rotate3d(0, 0, 1, 90deg);
`;
const arrowRight = css`
  transform: rotate3d(0, 0, 1, 0deg);
`;

const RefinementList = ({
  items,
  refine,
  searchForItems,
  label,
  isBool,
  labelTrue,
  labelFalse,
  isSearchable,
}) => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const [hasItems, setHasItems] = useState(false);
  const [search, setSearch] = useState("");
  const dropdownRef = useRef();

  useEffect(() => {
    if (items.length > 1 && isSearchable) {
      const t = setTimeout(() => {
        searchForItems(search);
      }, 200);
      return () => {
        clearTimeout(t);
      };
    }
  }, [isSearchable, items.length, search, searchForItems]);

  useEffect(() => {
    if (items.length >= 2) {
      setHasItems(true);
    }
  }, [items.length]);

  const toggleShowOptions = () => {
    setActive(a => !a);
  };

  useOutsideClick(dropdownRef, () => {
    dropdownRef && active && setActive(false);
  });

  const getBoolLabel = label => (label === "true" ? labelTrue : labelFalse);

  if (!hasItems) return null;

  const renderItem = (item, i) => (
    <Option
      key={i}
      buttonTheme="tertiary"
      style={{ fontWeight: item.isRefined ? "bold" : "" }}
      isRefined={item.isRefined}
      color={theme.filterColor}
      onClick={event => {
        event.preventDefault();
        refine(item.value);
      }}
    >
      {isBool ? getBoolLabel(item.label) : item.label}
      <Count>({item.count})</Count>
      {item.isRefined && (
        <Tick name="tick" size={24} height={24} fill={theme.filterColor} />
      )}
    </Option>
  );

  return (
    <SelectWrapper ref={dropdownRef}>
      <SelectButton
        buttonTheme="primaryGreyDark"
        bgColor={theme.filterBackgroundColor}
        color={theme.filterColor}
        onClick={toggleShowOptions}
      >
        {label}
        <Arrow
          css={active ? arrowDown : arrowRight}
          name="arrow"
          size={6}
          height={10}
          fill={theme.foregroundColor}
        />
      </SelectButton>
      <SelectContainer active={active}>
        {isSearchable && (
          <SearchInputWrapper bgColor={theme.filterBackgroundColor}>
            <SearchInput
              type="search"
              placeholder={`${t("search_placeholder")}...`}
              onChange={event => setSearch(event.currentTarget.value)}
              bgColor={theme.filterBackgroundColor}
              value={search}
            />
            {items.length < 1 && (
              <NoResult color={theme.filterColor}>
                {t("search_Filter_no_result")}
              </NoResult>
            )}
          </SearchInputWrapper>
        )}
        {items.length > 0 && (
          <OptionWrapper
            isSearchable={isSearchable}
            bgColor={theme.filterBackgroundColor}
          >
            <ScrollContainer>{items.map(renderItem)}</ScrollContainer>
          </OptionWrapper>
        )}
      </SelectContainer>
    </SelectWrapper>
  );
};

RefinementList.propTypes = {
  items: PropTypes.array.isRequired,
  currentRefinement: PropTypes.array,
  createURL: PropTypes.func,
  label: PropTypes.string,
  refine: PropTypes.func,
  searchForItems: PropTypes.func,
  isBool: PropTypes.bool,
  labelTrue: PropTypes.string,
  labelFalse: PropTypes.string,
  isSearchable: PropTypes.bool,
};

const SearchRefinementList = connectRefinementList(RefinementList);

export default SearchRefinementList;
